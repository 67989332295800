import { render, staticRenderFns } from "./merch-deposit-orders.vue?vue&type=template&id=65afc90a&scoped=true&"
import script from "./merch-deposit-orders.ts?vue&type=script&lang=ts&"
export * from "./merch-deposit-orders.ts?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "65afc90a",
  null
  
)

export default component.exports